// Fonts 
/* poppins-300 - latin */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/FjallaOne-Regular.ttf');
  src: local(''),
       url('../fonts/FjallaOne-Regular.ttf') format('truetype')
}

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}



/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}


/* hind-300 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/hind-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/hind-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/hind-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/hind-v16-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/hind-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/hind-v16-latin-300.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-500 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/hind-v16-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/hind-v16-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/hind-v16-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/hind-v16-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/hind-v16-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/hind-v16-latin-500.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-regular - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/hind-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/hind-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/hind-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/hind-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/hind-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/hind-v16-latin-regular.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-600 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/hind-v16-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/hind-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/hind-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/hind-v16-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/hind-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/hind-v16-latin-600.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-700 - latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/hind-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/hind-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/hind-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/hind-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/hind-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/hind-v16-latin-700.svg#Hind') format('svg'); /* Legacy iOS */
}

html{
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  font-family: $extra-font;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  // color: #3a405b;
  color: rgba(0,0,0,0.65);
  background: $navbar-black;

}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-family: $secondary-font;
  font-weight:600;
  color: $black;
}

h1 ,.h1{
  font-size: 2.5rem;
  
}

h2,.h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
}

h3,.h3 {
  font-size: 1.5rem;
}

h4,.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,.h5 {
  font-size: 1.25rem;
}

h6,.h6 {
  font-size: 1rem;
}


p{
  line-height: 30px;
}