.contact-form-wrap{
      .form-group{
        padding-bottom: 15px;
        margin: 0px;
        .form-control{
          background:$secondary-color;
          height: 48px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
      .form-group-2{
        margin-bottom: 13px;
        textarea{
          background: $secondary-color;
          height: 135px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
}


.address-block {
  li {
    margin-bottom:10px;
    i {
      font-size: 20px;
      width: 20px;
    }
  }
}


.social-icons {
  li {
    margin:0 6px;
  }
 
  i{
    margin-right: 15px;
    font-size: 25px;
  }
}
