.slider {
  background: url("../images/bg/Fotor_AI.png") no-repeat;
  background-position: center;
  background-size: cover;
  padding: 150px 0 100px 0;
  position: relative;
  margin-top: -50px;
  @extend .overly;

  .block {
    h1 {
      font-size: 70px;
      line-height: 80px;
      font-weight: 600;
      color: $light;

    }

    p {
      margin-bottom: 30px;
      color: #b9b9b9;
      font-size: 18px;
      line-height: 27px;
      font-weight: 300;
    }

    span {
      letter-spacing: 1px;
    }
  }
}