// Counter Section 

.counter-item{
	.counter-stat{
		font-size: 50px;
	}

	p{
		margin-bottom: 0px;
	}

}

.bg-counter{
	background-size: cover;
	@extend .overly-2;
}