.main-banner-item {
  padding-top: 30px;
  text-align: center;
}

.main-banner-item img {
  max-height: 300px;
  max-width: 90%;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}