$light: #fff;
$primary-color: #104A6B;
$secondary-color: #FFB30F;
$faded-secondary-color: #DBA93B;
$blog-background-color: #e3e3e3;
$event-color: #c54041;
$law-color: #b99769;
$black: #242424;
$navbar-black: #222328;
$border-color:#dedede;
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Fjalla One', sans-serif;
$extra-font:'Hind', serif;
