@import 'variables.scss';

@import 'mixins.scss';

@import 'media-query.scss';

@import 'typography.scss';

@import 'common.scss';

@import 'main.scss';

@import 'templates/header.scss';

@import 'templates/navigation.scss';
@import 'templates/backgrounds.scss';

@import 'templates/slider.scss';
@import 'templates/main-banner.scss';
@import 'templates/intro.scss';
@import 'templates/about.scss';
@import 'templates/counter.scss';
@import 'templates/team.scss';

@import 'templates/service.scss';
@import 'templates/cta.scss';
@import 'templates/review.scss';
@import 'templates/pricing.scss';
@import 'templates/portfolio.scss';
@import 'templates/contact.scss';
@import 'templates/blog.scss';
@import 'templates/video.scss';
@import 'templates/single-post.scss';
@import 'templates/blog-sidebar.scss';

@import 'templates/footer.scss';
@import 'templates/responsive.scss';




